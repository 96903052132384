export const seo = {
  url: '404',
  title: {
    pl: '404 | Strona nie odnaleziona',
    en: '404 | Page not found',
  },
  desc: {
    pl: 'Wygląda na to, że strona, którą chcesz odwiedzić nie istnieje. Kilknij przycisk, aby wrócić na stronę główną',
    en: 'Seems like the page you are looking for does not exist. Go to homepage by clicking the button',
  },
  keywords: ['omida', 'sea and air', 'sea & air', 'certyfikaty', 'tsl'],
}

export const intro = {
  title: {
    pl: 'Mielizna!',
    en: 'Shallow!',
  },
  desc: {
    pl: 'Wygląda na to, że strona, którą chcesz odwiedzić nie istnieje. Kilknij przycisk, aby wypłynąć na stronę główną',
    en: 'Seems like the page you are looking for does not exist. Sail back to homepage by clicking the button',
  },
  button: {
    text: {
      pl: 'Zwrot!',
      en: 'Return!',
    },
    link: '/',
  },
}
